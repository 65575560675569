import data from "./data.base64.json";
import "./App.scss";
import PodcastItem from "./components/PodcastItem";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import ShareButtons from "./components/ShareButtons";

function App() {
  const [platform, setPlatform] = useState("all");
  const [paid, setPaid] = useState("all");
  const [podState, setPodState] = useState("all");
  const [sex, setSex] = useState("all");
  const [hostCount, setHostCount] = useState("all");
  const [music, setMusic] = useState("all");
  const [interview, setInterview] = useState("all");
  const [dramatized, setDramatized] = useState("all");
  const [episodeCount, setEpisodeCount] = useState("all");
  const [avgDuration, setAvgDuration] = useState("all");
  const [searchTerm, setSearch] = useState("");
  const [showRecentOnly, setShowRecentOnly] = useState(false);
  const [recommendations, setRecommendations] = useState(false);

  const onPlatformChange = (event) => setPlatform(event.target.value);
  const onPaidChange = (event) => setPaid(event.target.value);
  const onPodStateChange = (event) => setPodState(event.target.value);
  const onSexChange = (event) => setSex(event.target.value);
  const onHostCountChange = (event) => setHostCount(event.target.value);
  const onMusicChange = (event) => setMusic(event.target.value);
  const onInterviewChange = (event) => setInterview(event.target.value);
  const onDramatizedChange = (event) => setDramatized(event.target.value);
  const onEpisodeCountChange = (event) => setEpisodeCount(event.target.value);
  const onAvgDurationChange = (event) => setAvgDuration(event.target.value);
  const onSearch = (event) => setSearch(event.target.value);
  const [hasScrolled, setHasScrolled] = useState(false);

  const myRecommendations = [
    "Pahuuden anatomia",
    "Vastausta vailla",
    "Piinan Kirous",
    "Ammattina rikos",
    "Jäljillä",
    "Tuhansien rikosten maa",
    "Myyrä",
    "Vankilamyytit",
    "Poliisin mieli",
    "JOKU TIETÄÄ JOTAIN",
  ];

  function resetSelections() {
    setPlatform("all");
    setPaid("all");
    setPodState("all");
    setSex("all");
    setHostCount("all");
    setMusic("all");
    setInterview("all");
    setShowRecentOnly(false);
    setDramatized("all");
    setEpisodeCount("all");
    setAvgDuration("all");
    setRecommendations(false);
    setSearch("");

    scrollToElement("home");
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 300) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Poista tapahtumankuuntelija komponentin poistuessa
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToElement = (id, offset = 0) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  function isMatchingPlatform(item, platform) {
    return platform === "all" || platform === item.platform;
  }

  function isMatchingPaidStatus(item, paid) {
    if (paid === "all") {
      return true;
    } else if (paid === "free") {
      return item.paid === "free" || item.paid === "partially_paid";
    } else if (paid === "paid") {
      return item.paid === "paid" || item.paid === "partially_paid";
    } else if (paid === "partially_paid") {
      return item.paid === "partially_paid";
    }
    return false;
  }

  function isMatchingPodStateStatus(item, podState) {
    if (podState === "all") {
      return true;
    }

    const parts = item.last_episode.split(".");
    const episodeDate = new Date(parts[2], parts[1] - 1, parts[0]); // Date is created using year, month (0-indexed), and day.

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    // Last episode is newer than three months
    if (podState === "active" && episodeDate > threeMonthsAgo) {
      return true;
    }

    // Last episode is older than three months
    if (podState === "break" && episodeDate <= threeMonthsAgo) {
      return true;
    }

    return false;
  }

  function isMatchingSex(item, sex) {
    if (sex === "all") {
      return true; // If the filter is set to "all", any value is acceptable
    }
    // Allow both "man" and "woman" when "both" is selected or vice versa
    return item.sex === sex || item.sex === "both" || sex === "both";
  }

  function isMatchingHostCount(item, hostCount) {
    return hostCount === "all" || hostCount === item.host_count;
  }

  function isMatchingMusic(item, music) {
    return (
      music === "all" ||
      (music === "false" && !item.music) ||
      (music === "true" && item.music)
    );
  }

  function isMatchingInterview(item, interviews) {
    return (
      interviews === "all" ||
      (interviews === "false" && !item.interviews) ||
      (interviews === "true" && item.interviews)
    );
  }

  function isMatchingDramatized(item, dramatized) {
    return (
      dramatized === "all" ||
      (dramatized === "false" && !item.dramatized) ||
      (dramatized === "true" && item.dramatized)
    );
  }

  function isMatchingEpisodeCount(item, episodeCount) {
    let lowNumber;
    let highNumber;

    const isLessThan = episodeCount.includes("<");
    const isRange = episodeCount.includes("-");
    const isMoreThan = episodeCount.includes(">");

    if (isLessThan) {
      highNumber = parseInt(episodeCount.replace("<", ""));
    }

    if (isRange) {
      lowNumber = parseInt(episodeCount.split("-")[0]);
      highNumber = parseInt(episodeCount.split("-")[1]);
    }

    if (isMoreThan) {
      lowNumber = parseInt(episodeCount.replace(">", ""));
    }

    return (
      episodeCount === "all" ||
      (isLessThan && item.episode_count < highNumber) ||
      (isRange &&
        item.episode_count >= lowNumber &&
        item.episode_count < highNumber) ||
      (isMoreThan && item.episode_count >= lowNumber)
    );
  }

  function isMatchingAvgDuration(item, avgDuration) {
    let lowNumber;
    let highNumber;

    const isLessThan = avgDuration.includes("<");
    const isRange = avgDuration.includes("-");
    const isMoreThan = avgDuration.includes(">");

    if (isLessThan) {
      highNumber = parseInt(avgDuration.replace("<", ""));
    }

    if (isRange) {
      lowNumber = parseInt(avgDuration.split("-")[0]);
      highNumber = parseInt(avgDuration.split("-")[1]);
    }

    if (isMoreThan) {
      lowNumber = parseInt(avgDuration.replace(">", ""));
    }

    return (
      avgDuration === "all" ||
      (isLessThan && item.avg_duration < highNumber) ||
      (isRange &&
        item.avg_duration >= lowNumber &&
        item.avg_duration < highNumber) ||
      (isMoreThan && item.avg_duration >= lowNumber)
    );
  }

  function matchesSearchTerm(item, searchTerm) {
    if (!searchTerm) {
      return true;
    }

    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  function isOlderThanThreeMonths(firstEpisode) {
    // Assuming the firstEpisode is in the format 'dd.MM.yyyy'
    const parts = firstEpisode.split(".");
    const episodeDate = new Date(parts[2], parts[1] - 1, parts[0]); // months are 0-based in JavaScript Date

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    return episodeDate <= threeMonthsAgo;
  }

  function decodeBase64Json(base64String) {
    const binaryString = atob(base64String);
    const binaryData = Uint8Array.from(binaryString, (char) =>
      char.charCodeAt(0)
    );
    const jsonString = new TextDecoder("utf-8").decode(binaryData);
    return JSON.parse(jsonString);
  }

  const json = decodeBase64Json(data.base64);

  const shuffledItems = useMemo(() => {
    let filteredItems = null;

    if (recommendations) {
      filteredItems = json.filter((item) =>
        myRecommendations.includes(item.name)
      );
    } else {
      filteredItems = json
        .filter((item) => item.url)
        .filter((item) => isMatchingPlatform(item, platform))
        .filter((item) => isMatchingPaidStatus(item, paid))
        .filter((item) => isMatchingPodStateStatus(item, podState))
        .filter((item) => isMatchingSex(item, sex))
        .filter((item) => isMatchingHostCount(item, hostCount))
        .filter((item) => isMatchingMusic(item, music))
        .filter((item) => isMatchingInterview(item, interview))
        .filter((item) => isMatchingDramatized(item, dramatized))
        .filter((item) => isMatchingEpisodeCount(item, episodeCount))
        .filter((item) => isMatchingAvgDuration(item, avgDuration))
        .filter((item) => matchesSearchTerm(item, searchTerm))
        .filter(
          (item) =>
            !showRecentOnly ||
            (item.first_episode && !isOlderThanThreeMonths(item.first_episode))
        );
    }

    shuffleArray(filteredItems);

    // Ensure "pahuuden-anatomia" is in the first three
    const targetIndex = filteredItems.findIndex(
      (item) => item.slug === "pahuuden-anatomia"
    );

    if (targetIndex > -1 && targetIndex >= 5) {
      const [targetItem] = filteredItems.splice(targetIndex, 1);
      const randomIndex = Math.floor(Math.random() * 5);
      filteredItems.splice(randomIndex, 0, targetItem);
    }

    return filteredItems;
  }, [
    platform,
    paid,
    podState,
    sex,
    hostCount,
    music,
    interview,
    showRecentOnly,
    dramatized,
    episodeCount,
    avgDuration,
    recommendations,
    searchTerm,
  ]);

  return (
    <div
      className={`home ${recommendations ? "recommendations" : ""}`}
      id="home"
    >
      <header className="">
        <div className="heading">
          <p>Löydä kotimainen</p>
          <h1>Rikospodcast</h1>
          {/* <span>BETA</span> */}
        </div>

        <div className="filters">
          <label>
            <span>Alusta</span>
            <select onChange={onPlatformChange} value={platform}>
              <option value="all">Ei väliä</option>
              <option value="spotify">Spotify</option>
              <option value="podimo">Podimo</option>
              <option value="podme">Podme</option>
              <option value="supla">Supla</option>
              <option value="areena">Areena</option>
            </select>
          </label>

          <label>
            <span>Maksullinen</span>
            <select onChange={onPaidChange} value={paid}>
              <option value="all">Ei väliä</option>
              <option value="free">Maksuton</option>
              <option value="paid">Maksullinen</option>
              <option value="partially_paid">Osittain</option>
            </select>
          </label>

          <label>
            <span>Tila</span>
            <select onChange={onPodStateChange} value={podState}>
              <option value="all">Ei väliä</option>
              <option value="active">Aktiivinen</option>
              <option value="break">Tauolla/Lopetettu</option>
            </select>
          </label>

          <label>
            <span>Hostien määrä</span>
            <select onChange={onHostCountChange} value={hostCount}>
              <option value="all">Ei väliä</option>
              <option value="single">Yksi</option>
              <option value="more_than_one">Useampi</option>
            </select>
          </label>

          <label>
            <span>Sukupuoli</span>
            <select onChange={onSexChange} value={sex}>
              <option value="all">Ei väliä</option>
              <option value="woman">Nainen</option>
              <option value="man">Mies</option>
            </select>
          </label>

          <label>
            <span>Taustamusiikki</span>
            <select onChange={onMusicChange} value={music}>
              <option value="all">Ei väliä</option>
              <option value="true">Kyllä</option>
              <option value="false">Ei</option>
            </select>
          </label>

          <label>
            <span>Sis. haastatteluja</span>
            <select onChange={onInterviewChange} value={interview}>
              <option value="all">Ei väliä</option>
              <option value="true">Kyllä</option>
              <option value="false">Ei</option>
            </select>
          </label>

          <label>
            <span>Sis. dramatisointia</span>
            <select onChange={onDramatizedChange} value={dramatized}>
              <option value="all">Ei väliä</option>
              <option value="true">Kyllä</option>
              <option value="false">Ei</option>
            </select>
          </label>

          <label>
            <span>Jaksojen määrä</span>
            <select onChange={onEpisodeCountChange} value={episodeCount}>
              <option value="all">Ei väliä</option>
              <option value="<10">Alle 10</option>
              <option value="10-40">10-40</option>
              <option value="40-80">40-80</option>
              <option value=">80">Yli 80</option>
            </select>
          </label>

          <label>
            <span>Keskipituus</span>
            <select onChange={onAvgDurationChange} value={avgDuration}>
              <option value="all">Ei väliä</option>
              <option value="<15">Alle 15 min</option>
              <option value="15-30">15-30 min</option>
              <option value="30-60">30-60 min</option>
              <option value=">60">Yli 60 min</option>
            </select>
          </label>

          <label className="search">
            <span>
              Vapaahaku <span className="badge">Uutta</span>
            </span>
            <input type="text" onChange={onSearch} value={searchTerm} />
          </label>
        </div>

        <div className="second-row">
          <label
            className="check recommendations"
            title="Näytä vain toimituksen ykkössuosikit."
          >
            <input
              type="checkbox"
              checked={recommendations}
              onChange={() => setRecommendations(!recommendations)}
            />
            Ylläpidon suositukset
          </label>

          <label
            className="check only-newest"
            title="Näytä podcastit, jotka ovat aloittaneet viimeisen 3kk aikana."
          >
            <input
              type="checkbox"
              checked={showRecentOnly}
              onChange={() => setShowRecentOnly(!showRecentOnly)}
            />
            Uutuudet
          </label>

          <button className="clear-selections" onClick={resetSelections}>
            <DeleteIcon className="delete-icon" />
            <p>Nollaa</p>
          </button>
        </div>
      </header>

      <ShareButtons size="36px" />

      <main id="main">
        {shuffledItems.length > 0 ? (
          <>
            <p>
              Osumia: {shuffledItems.length} / {json.length}
            </p>
            <div className="podcasts">
              {shuffledItems.map((item, index) => (
                <PodcastItem
                  style={{ animationDelay: `${index * 25}ms` }}
                  key={item.slug}
                  href={item.url}
                  src={`${process.env.PUBLIC_URL}/cover_art/${item.slug}.webp`}
                  alt={item.name}
                  recommended={myRecommendations.includes(item.name)}
                />
              ))}
            </div>
          </>
        ) : (
          <p className="no-matches">
            Antamillasi valinnoilla ei löytynyt yhtään osumaa :(.
            <span>
              Olemmeko tehneet virheen? Ilmoita asiasta{" "}
              <a href="mailto:info@rikospodcast.fi">info@rikospodcast.fi</a>
            </span>
          </p>
        )}
        <div className="text-content-wrapper">
          <div className="text-content">
            <h2>Rikospodcast.fi — Kotimaisten true crime -podcastien koti</h2>

            <p>
              rikospodcast.fi on luotu kaikille true crime -tarinoiden
              ystäville. Sivusto tarjoaa kattavan hakukoneen, jonka avulla
              löydät helposti ja nopeasti juuri sinua kiinnostavat kotimaiset
              rikospodcastit.
            </p>

            <h3>Monipuoliset suodatus&shy;mahdollisuudet</h3>

            <p>
              Kattavien suodatusmahdollisuuksien avulla voit rajata hakutuloksia
              seuraavien kriteerien perusteella:
            </p>

            <ul>
              <li>
                <strong>Alusta:</strong> Suodata podcasteja alustan perusteella
                (Spotify, Podimo, Podme, Supla, Areena).
              </li>
              <li>
                <strong>Maksullisuus:</strong> Maksumuurilla vai ilman?
              </li>
              <li>
                <strong>Podcastin tila:</strong>
                <ul>
                  <li>
                    <strong>Aktiivinen:</strong> Viimeisin jakso julkaistu 3kk
                    sisällä.
                  </li>
                  <li>
                    <strong>Tauolla/Lopetettu:</strong> Viimeisin jakso
                    julkaistu yli 3kk sitten.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Hostien määrä:</strong> Valitse podcastit yhden tai
                useamman hostin perusteella.
              </li>
              <li>
                <strong>Hostin sukupuoli:</strong> Etsi podcasteja hostin
                sukupuolen mukaan.
              </li>
              <li>
                <strong>Taustamusiikki:</strong> Suodata podcastit, jotka
                sisältävät taustamusiikkia.
              </li>
              <li>
                <strong>Haastattelut:</strong> Löydä podcastit, jotka sisältävät
                haastatteluja.
              </li>
              <li>
                <strong>Dramatisoinnit:</strong> Suodata podcastit, jotka
                sisältävät dramatisointia kuten ääniefektejä, näyteltyjä
                kohtauksia tai muita dramatisoituja elementtejä.
              </li>
              <li>
                <strong>Jaksojen määrä:</strong> Joskus määrä ratkaisee.
              </li>
              <li>
                <strong>Jakson keskipituus:</strong> Suodata podcasteja jaksojen
                keskipituuden mukaan.
              </li>
              <li>
                <strong>Vain uutuudet:</strong> Näytä podcastit, jotka ovat
                aloittaneet viimeisen 3kk aikana.
              </li>
            </ul>

            <h2>Löydä etsimäsi kotimainen rikospodcast nopeasti</h2>

            <p>
              Rikospodcast.fi tekee kotimaisten true crime -podcastien
              löytämisestä todella helppoa. Olitpa sitten uusi tai kaiken
              kuullut true crime -konkari, sivuston avulla löydät nopeasti uudet
              suosikit ja mielenkiintoisimmat tarinat.
            </p>
            <p>
              Pyrimme tarjoamaan parhaan mahdollisen käyttäjäkokemuksen ja
              päivitämme hakukoneemme säännöllisesti uusilla podcasteilla ja
              suodatusvaihtoehdoilla.
            </p>
            <p>
              Aloita siis matkasi kotimaisten rikosaiheisten podcastien
              maailmaan jo tänään! Käytä rikospodcast.fi-sivustoamme ja löydä
              seuraava suosikkipodcastisi.
            </p>
            <p>
              Tervetuloa rikospodcast.fi-yhteisöön – meillä rikokset eivät jää
              koskaan ratkaisematta!
            </p>
          </div>
        </div>
      </main>
      <footer>
        <p>
          Sisältääkö listaus virheen tai haluatko ilmoittaa uuden podcastin?
          <br />
          Ota yhteyttä{" "}
          <a href="mailto:info@rikospodcast.fi">info@rikospodcast.fi</a>
        </p>
        <ShareButtons />
      </footer>
    </div>
  );
}

export default App;
